import React, { useState } from "react";
import { Link } from "gatsby";

// Components
import Seo from "../components/Seo";
import LayoutNoMenu from "../components/LayoutNoMenu";

import frontPageVideoMobile from "../assets/Visual_Vocab_Portrait_H264.mp4";
import frontPageVideo from "../assets/Visual_Vocab_Landscape_H264.mp4";

const IndexPage = () => {
  const [muted, setMuted] = useState(true);

  function onClick() {
    setMuted(!muted);
  }

  return (
    <LayoutNoMenu>
      <Seo title={`Kingston School of Art Foundation 2021`} />
      <div className="frontPage">
        <h1 className="frontPageTitle">
          Kingston School of Art Foundation 2021
        </h1>

        <div className="videoPlayerHome">
          <Link to="/exhibition/">
            <div className="videoWrapper">
              <video
                id="video1"
                autoPlay
                muted={muted}
                className="thevideomobile"
                loop
                playsInline
                controls={false}
              >
                <source src={frontPageVideoMobile} type="video/mp4" />
                <source src={frontPageVideoMobile} type="video/webm" />
                Your browser does not support the video tag.
              </video>
              <video
                autoPlay
                muted={muted}
                controls={false}
                className="thevideodesktop"
                playsInline
                loop
              >
                <source src={frontPageVideo} type="video/mp4" />
                <source src={frontPageVideo} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Link>
          <button className={muted ? "" : "soundOn"} onClick={() => onClick()}>
            {muted ? "Sound on" : "Mute"}
          </button>
        </div>
        <nav className="menu noFixed ">
          <div className="frontMenuMobile">
            <Link className="menuLink menuTitle" to="/exhibition/">
              <p>Enter exhibition</p>
            </Link>
          </div>
          <div className="frontMenuDesktop">
            <Link
              className="menuLink"
              activeClassName="menuActive"
              to={`/exhibition/`}
            >
              <p>Exhibition</p>
            </Link>
            <Link
              className="menuLink"
              activeClassName="menuActive"
              to={`/cinema/`}
            >
              <p>Cinema</p>
            </Link>

            <Link
              className="menuLink"
              activeClassName="menuActive"
              to={`/radio/`}
            >
              <p>Radio</p>
            </Link>

            <Link
              className="menuLink"
              activeClassName="menuActive"
              to={`/all/`}
            >
              <p>Index</p>
            </Link>
          </div>
        </nav>
      </div>
    </LayoutNoMenu>
  );
};

export default IndexPage;
